import {
  faDollarSign,
  faPuzzlePiece,
  faHandPointer,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageWrapper } from 'client/components/generic/PageWrapper';
import { useUser } from 'client/contexts/user';
import { Images, Routes } from 'client/enums';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { ContactForm } from './ContactForm';
import { Modal } from './Modal';

export const HomeComponent = () => {
  const user = useUser();
  const router = useRouter();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const getOnClick = user?.id
    ? () => router.push(Routes.REPORTS)
    : () => router.push(Routes.LOGIN);
  const getButtonText = user?.id ? 'GO TO REPORTS' : 'REQUEST ACCOUNT';

  return (
    <PageWrapper>
      <div className="flex -mt-9 flex-col lg:flex-row lg:bg-[url('/assets/ESG-Hero-new.svg')] lg:bg-no-repeat lg:bg-right shadow-primary lg:h-[700px]">
        <div className="flex flex-col pt-4 pb-8 w-[90%] lg:w-1/2 ml-[5%] lg:ml-24 lg:pt-[100px] lg:pb-24">
          <div className="text-4xl text-gray-900 font-medium tracking-wider">
            B2B emissions reporting for fleet operations and fuel marketers.
          </div>
          <div className="text-4xl text-gray-900 font-medium tracking-wider">
            Backed by industry standard methods and third-party experts.
          </div>
          <div className="text-4xl text-blue-primary font-medium tracking-wider">
            Reporting Made Simple.
          </div>
          <div className="text-md text-gray-900 mt-8">
            Carbon Avoidance Tracker helps you meet client demands for emissions
            reporting.
          </div>
          <div className="text-md text-gray-900 mt-8">
            We incorporate known standards and include open-source models while
            applying third party engineering review for quality assurance.
          </div>
          <div className="flex flex-row flex-wrap mt-8">
            <button
              className="button--primary w-full lg:w-52 lg:mr-8"
              onClick={getOnClick}
            >
              {getButtonText}
            </button>
            <button
              className="button--secondary w-full mt-4 lg:mt-0 lg:w-52 lg:mr-8 "
              onClick={() => setModalIsOpen(true)}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 pt-4 lg:grid-cols-4 lg:pt-12 justify-items-center">
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            className="text-green-primary"
            icon={faHandPointer}
            size="2x"
          />
          <div className="my-4 lg:my-0 ml-4 text-xl font-medium text-gray-900">
            Easy-to-Use Interface
          </div>
        </div>
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            className="text-green-primary"
            icon={faDollarSign}
            size="2x"
          />
          <div className="my-4 lg:my-0 ml-4 text-xl font-medium text-gray-900">
            Cost-Effective
          </div>
        </div>
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            className="text-green-primary"
            icon={faPuzzlePiece}
            size="2x"
          />
          <div className="my-4 lg:my-0 ml-4 text-xl font-medium text-gray-900">
            Unique Programs
          </div>
        </div>
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            className="text-green-primary"
            icon={faChartLine}
            size="2x"
          />
          <div className="my-4 lg:my-0 ml-4 text-xl font-medium text-gray-900">
            Top-Line Growth
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 px-8 py-4 lg:grid-cols-2 lg:items-center lg:gap-12 lg:px-[10%] lg:py-12">
        <div className="col-span1 lg:col-span-2 reports-stack">
          <img
            alt="Carbon Avoidance Tracker"
            className="w-full self-center max-w-[800px]"
            src={Images.FILogo}
          />
          <div className="text-gray-900 font-medium text-center text-4xl my-2">
            B2B Carbon Reporting
          </div>
          <div className="text-gray-900 text-center leading-6 text-md my-4 px-[10%]">
            Over the past 12 months, large organizations have begun to assess
            their total (direct and indirect) emissions throughout their supply
            chains. New opportunities to engage with these large companies will
            include a request for your emissions, should you be selected as a
            partner. Increasingly, emissions reporting is becoming a
            pre-requisite for doing business and even a requirement to maintain
            existing or preferred status.
          </div>
          <div className="text-gray-900 text-center leading-6 text-md my-4">
            To meet these immediate industry needs, the non-profit
            Transportation Energy Institute has created the business-to-business
            (B2B) reporting tool - Carbon Avoidance Tracker. Reports are
            designed to be shared across inner company divisions or with
            customers seeking this critical data. For more information, contact
            Jeff Hove at jhove@transportationenergy.org or call (703)518-7972.
            Or simply fill out the Contact Us form below.
          </div>
          <div className="flex mt-8 justify-center">
            <button
              className="button--secondary w-full mt-4 lg:mt-0 lg:w-52 lg:mr-8 "
              onClick={() => setModalIsOpen(true)}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="grid place-items-center aspect-video md:aspect-auto">
          <div className="w-3/5 text-gray-900 font-medium text-center text-4xl mt-2 mb-10">
            Full Service Environmental, Social, and Governance (ESG) Reporting
            Tools: ESG Integrity
          </div>
          <div className="w-full h-full grid place-items-center md:w-[640px] md:h-[360px]">
            <ReactPlayer
              controls
              height="100%"
              loop
              url="https://www.youtube.com/watch?v=S88AcP39IXE&t=4s"
              width="95%"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 lg:px-24 px-8 py-4 lg:py-12 ">
        <div className="flex flex-col col-span-1 lg:col-span-3">
          <div className="text-2xl font-medium text-center text-blue-primary mx-auto">
            IMPROVING THE TRANSPORTATION INDUSTRY
          </div>
          <div className="text-5xl font-medium text-center text-gray-900 mx-auto my-6">
            Equipping Companies with Automated Reporting
          </div>
          <div className="text-md text-center text-gray-900 leading-8 mb-6">
            Whether you’re a small or large Fuel Marketer or Fleet Operator, ESG
            Integrity offers best practices and third-party assurances at a
            fraction of traditional costs. Online applications include step-
            by-step guidance and our professionals are available for further
            consulting, as-needed.
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-8 my-4 lg:my-0 border-2 border-blue-primary rounded-3xl">
          <div className="text-2xl font-medium text-gray-900 mb-2">
            GREET Modeling
          </div>
          <img
            alt="GREET Modeling"
            className="w-[150px] lg:w-[200px]"
            src={Images.GREETModeling}
          />
          <div className="text-lg mt-4 mx-4 text-center text-gray-900">
            Reliable data calculations backed by Argonne National Labs.
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-8 my-4 lg:my-0 border-2 border-blue-primary rounded-3xl">
          <div className="text-2xl font-medium text-gray-900 mb-2">
            Piecewise Data Entry
          </div>
          <img
            alt="Piecewise Data Entry"
            className="w-[150px] lg:w-[200px]"
            src={Images.DataEntry}
          />
          <div className="text-lg mt-4 mx-4 text-center text-gray-900">
            Save partial reports and return to where you left off.
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-8 my-4 lg:my-0 border-2 border-blue-primary rounded-3xl">
          <div className="text-2xl font-medium text-center text-gray-900 mb-2">
            Easy ESG Reporting and B2B Sub-Reporting
          </div>
          <img
            alt="Easy Reporting"
            className="w-[150px] lg:w-[200px]"
            src={Images.DataEntry}
          />
          <div className="text-lg mt-4 mx-4 text-center text-gray-900">
            URL Link, PDF, Document, and XML reporting formats.
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 px-8 py-4 bg-gray-25 lg:grid-cols-2 lg:items-center lg:gap-12 lg:px-[10%] lg:py-12">
        <div className="flex flex-col col-span-1 lg:col-span-2">
          <div className="text-5xl font-medium text-center text-gray-900 my-6">
            Why Are ESG Reporting Capabilities Important?
          </div>
          <div className="text-md text-center text-gray-900 leading-8 mb-6">
            Environmental, Social & Governance Reporting (ESG) is quickly moving
            from a voluntary corporate PR tool to a regulated B2B prerequisite.
            By investing in a sound ESG Reporting plan, companies can improve
            top-line growth, increase access to investor capital, & ensure
            compliance with future environmental standards.
          </div>
        </div>
        <div className="flex flex-col lg:ml-16 text-center lg:text-left">
          <div className="text-xl text-blue-primary font-medium my-2">
            Corporate Governance
          </div>
          <div className="text-4xl text-gray-900 font-medium my-2">
            Improving Company Finances
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            With investor demands for corporate sustainability disclosures
            rising 140% from 2019 to 2020, transparent investment products
            present a unique growth opportunity for companies that may soon be
            required to report their environmental and social commitments.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            By getting ahead of the SEC’s proposed reporting requirements,
            companies that have sustainable practices in place are better
            situated to receive additional capital investment than those who do
            not.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            Sustainable investment options are the next growth frontier.
          </div>

          <a
            className="button--secondary w-3/5 self-center"
            href="https://www.mckinsey.com/capabilities/strategy-and-corporate-finance/our-insights/why-esg-is-here-to-stay"
            rel="noreferrer"
            target="_blank"
          >
            More Information
          </a>
        </div>
        <img
          alt="Improving Company Finances"
          className="w-0 lg:w-3/4"
          src={Images.ImprovingFinancials}
        />
        <img
          alt="Shaping Public Perception"
          className="w-0 lg:w-3/4 lg:ml-auto"
          src={Images.PublicPerception}
        />
        <div className="flex flex-col lg:ml-16 text-center lg:text-left">
          <div className="text-xl text-blue-primary font-medium my-2">
            Social Issues
          </div>
          <div className="text-4xl text-gray-900 font-medium my-2">
            Shaping Public Perception
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            When tied to relevant and timely industry news, a strategic public
            relations approach that highlights a company’s sustainable business
            practices can generate valuable media attention.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            With the growth of social media outlets, a company’s public
            perception can be greatly improved with transparent disclosure of
            environmental, social and governance data. Companies that fail to
            meet investor demands for this reporting can be ostracized by
            environmentally-conscious investors and consumers.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            ESG Integrity can ensure that your company meets desired reporting
            requirements in a simple yet effective manner.
          </div>
        </div>
        <div className="flex flex-col lg:ml-16 text-center lg:text-left">
          <div className="text-xl text-blue-primary font-medium my-2">
            Environmental Management
          </div>
          <div className="text-4xl text-gray-900 font-medium my-2">
            Decreasing Emissions Output
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            Under the SEC’s newest proposed rule, investment funds will be
            required to report the greenhouse gas emissions associated with
            their portfolio investments. Companies that fail to report both
            Scope 1 and Scope 2 emissions risk losing out on future investment
            from large investment funds.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            This proposed rule would indirectly require associated companies –
            both public and private – to report their greenhouse gas emissions
            data to their partner-company required to disclose its data to the
            SEC.
          </div>
          <div className="text-md text-gray-900 my-4 leading-6">
            Carbon emissions reporting requirements will affect every
            organization in the supply chain. If an organization is not in
            compliance, it risks significant financial losses, both from
            investors and potential business partners.
          </div>
        </div>
        <img
          alt="Decreasing Emissions Output"
          className="w-0 lg:w-3/4"
          src={Images.DecreasingEmissions}
        />
        <div className="col-span1 lg:col-span-2 reports-stack">
          <div className="text-green-primary font-medium text-center text-2xl">
            How can ESG Integrity help you?
          </div>
          <div className="text-gray-900 font-medium text-center text-4xl my-2">
            A Program Tailored to You
          </div>
          <div className="text-gray-900 text-center leading-6 text-md my-4 px-[10%]">
            Here at ESG Integrity we recognize the unique characteristics and
            business needs of fuel marketers and fleet operators. The ESGi
            application is specifically designed to meet these needs and is not
            intended for reporting outside of our expertise. By taking this
            approach, ESGi has been able to create a precise reporting tool
            which also significantly reduces your costs.
          </div>
          <div className="text-gray-900 text-center leading-6 text-md my-4">
            That’s why we provide reporting solutions that are unique to your
            needs and guidance under multiple Sustainability Reporting
            Frameworks. By tracking data and applying transparent methods of
            emissions calculations, we lower your costs and provide you with the
            metrics needed to grow.
          </div>
          <div className="flex flex-row flex-wrap mt-8 justify-center">
            <button
              className="button--primary w-full lg:w-52 lg:mr-8"
              data-cy="call-to-action"
              onClick={getOnClick}
            >
              {getButtonText}
            </button>
            <button
              className="button--secondary w-full mt-4 lg:mt-0 lg:w-52 lg:mr-8 "
              onClick={() => setModalIsOpen(true)}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <Modal onClose={() => setModalIsOpen(false)} show={modalIsOpen}>
        <ContactForm />
      </Modal>
    </PageWrapper>
  );
};
